import type { BreakpointValues } from "@/hooks/use-viewport-breakpoint.hook";
import resolveConfig from "tailwindcss/resolveConfig";
import { content, theme } from "../../tailwind.config";

const tailwindConfig = resolveConfig({ content, theme });

const tailwindScreenSize = (tailwindConfig.theme.container.screens ??
  {}) as Record<BreakpointValues, string>;

export { tailwindScreenSize };
