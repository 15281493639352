const { fontFamily } = require("tailwindcss/defaultTheme");

/**
 * @type {import("tailwindcss").Config}
 */
module.exports = {
  content: ["./src/**/*.{ts,tsx}"],
  theme: {
    colors: ({ colors: defaultColors }) => ({
      // Built-in:
      current: defaultColors.current,
      inherit: defaultColors.inherit,
      transparent: defaultColors.transparent,
      // Greyscale:
      white: defaultColors.white,
      silent: "hsl(0 0% 99% / <alpha-value>)",
      whisper: "hsl(0 0% 93% / <alpha-value>)",
      smoke: "hsl(0 0% 88% / <alpha-value>)",
      silver: "hsl(0 0% 80% / <alpha-value>)",
      nobel: "hsl(0 0% 60% / <alpha-value>)",
      dim: "hsl(0 0% 40% / <alpha-value>)",
      charcoal: "hsl(0 0% 20% / <alpha-value>)",
      nero: "hsl(0 0% 13% / <alpha-value>)",
      slate: "hsl(205 15% 15% / <alpha-value>)",
      black: "hsl(0 0% 7% / <alpha-value>)", // https://ux-news.com/color-psychology-in-ux-design/?ref=web-design-weekly.com
      // Theme:
      primary: {
        DEFAULT: "hsl(var(--theme-color-primary-base) / <alpha-value>)",
        hover: "hsl(var(--theme-color-primary-hover) / <alpha-value>)",
      },
      secondary: {
        DEFAULT: "hsl(var(--theme-color-secondary-base) / <alpha-value>)",
        hover: "hsl(var(--theme-color-secondary-hover) / <alpha-value>)",
      },
      "brand-logo": {
        primary: "hsl(var(--theme-color-brand-logo-primary) / <alpha-value>)",
        secondary:
          "hsl(var(--theme-color-brand-logo-secondary) / <alpha-value>)",
      },
      "site-logo": {
        primary: "hsl(var(--theme-color-site-logo-primary) / <alpha-value>)",
        secondary:
          "hsl(var(--theme-color-site-logo-secondary) / <alpha-value>)",
      },
      grid: {
        primary: "hsl(var(--theme-grid-primary-color) / <alpha-value>)",
        secondary: "hsl(var(--theme-grid-secondary-color) / <alpha-value>)",
      },
      // Accent:
      error: defaultColors.red,
      success: defaultColors.green,
      info: defaultColors.blue,
      warning: defaultColors.yellow,
      highlight: "hsl(var(--theme-color-highlight) / <alpha-value>)",
      muted: "hsl(var(--theme-color-muted) / <alpha-value>)",
      accent: {
        DEFAULT: "hsl(var(--theme-color-accent) / <alpha-value>)",
        hover: "hsl(var(--theme-color-accent-hover) / <alpha-value>)",
      },
    }),
    container: {
      center: true,
      padding: "1rem",
      screens: {
        sm: "48rem",
        md: "64rem",
        lg: "75rem", // 1200px when the rem unit is 16px (the browser default).
        xl: "83rem",
        "2xl": "83rem",
      },
    },
    fontSize: {
      xs: [
        "var(--font-size-xs)",
        {
          lineHeight: "var(--line-height-xs)",
          letterSpacing: "var(--letter-spacing-xs)",
        },
      ],
      sm: ["var(--font-size-sm)", "var(--line-height-sm)"],
      base: ["var(--font-size-base)", "var(--line-height-base)"],
      lg: ["var(--font-size-lg)", "var(--line-height-lg)"],
      xl: ["var(--font-size-xl)", "var(--line-height-xl)"],
      "2xl": ["var(--font-size-2xl)", "var(--line-height-2xl)"],
      "3xl": ["var(--font-size-3xl)", "var(--line-height-3xl)"],
      "4xl": ["var(--font-size-4xl)", "var(--line-height-4xl)"],
      "5xl": ["var(--font-size-5xl)", "var(--line-height-5xl)"],
      "6xl": ["var(--font-size-6xl)", "var(--line-height-6xl)"],
      "7xl": ["var(--font-size-7xl)", "var(--line-height-7xl)"],
      "8xl": ["var(--font-size-8xl)", "var(--line-height-8xl)"],
    },
    fontWeight: {
      thin: "100",
      extralight: "200",
      light: "300",
      normal: "400",
      medium: "500",
      semibold: "600",
      bold: "700",
      extrabold: "800",
      black: "900",
      "theme-bold": "var(--theme-bold)",
      "theme-heading-weight": "var(--theme-heading-weight)",
    },
    screens: {
      sm: "40rem", // 640px
      md: "48rem", // 768px
      lg: "64rem", // 1024px when the rem unit is 16px (the browser default).
      xl: "83rem", // 1328px
      "2xl": "102rem", // 1632px
    },
    spacing: {
      0: "0",
      em: "1em",
      px: "0.0625rem",
      0.25: "0.125rem",
      0.5: "0.25rem",
      0.75: "0.375rem",
      0.875: "0.4375rem",
      1: "0.5rem",
      1.25: "0.625rem",
      1.5: "0.75rem",
      1.75: "0.875rem",
      2: "1rem",
      2.5: "1.25rem",
      3: "1.5rem",
      4: "2rem",
      4.5: "2.25rem",
      5: "2.5rem",
      5.25: "2.625rem",
      5.5: "2.75rem",
      5.75: "2.875rem",
      6: "3rem",
      7: "3.5rem",
      8: "4rem",
      9: "4.5rem",
      10: "5rem",
      11: "5.5rem",
      12: "6rem",
      20: "10rem",
      27: "13.5rem",
      36: "18rem",
      "1/20": "5%",
    },
    extend: {
      aspectRatio: {
        "3/2": "3 / 2",
        "4/3": "4 / 3",
        "16/9": "16 / 9", // This exists as aspect-video in Tailwind, but this is more understandable
      },
      borderWidth: {
        5: "5px",
        6: "6px",
      },
      fontFamily: {
        body: "var(--font-body)",
        heading: "var(--font-heading)",
        "article-body": "var(--theme-font-article-body)",
        "article-heading": "var(--theme-font-article-heading)",
      },
      gridRowEnd: {
        10: "10",
      },
      gridTemplateColumns: {
        16: "repeat(16, minmax(0, 1fr))",
      },
      minHeight: {
        5: "2.5rem",
        6: "3rem",
      },
      minWidth: {
        xs: "18rem",
      },
      scale: {
        99: "0.99",
      },
      boxShadow: {
        xl: "1px 1px 5px rgba(0, 0,  0, 0.5)",
      },
      typography: ({ theme }) => {
        return {
          DEFAULT: {
            css: {
              "h1, h2, h3, h4, h5, h6": {
                fontWeight: "var(--theme-heading-weight)",
                fontStyle: theme("fontStyle.normal"),
                fontFamily: "var(--theme-font-article-heading)",
                lineHeight: theme("lineHeight.tight"),
              },
              p: {
                fontFamily: "var(--theme-font-article-body)",
                // https://github.com/tailwindlabs/tailwindcss-typography/issues/330
                fontSize: theme("fontSize.lg")[0],
                lineHeight: theme("lineHeight.7"),
              },
              table: {
                fontFamily: "var(--theme-font-article-body)",
                fontSize: theme("fontSize.lg")[0],
              },
              "strong, b": {
                fontWeight: "var(--theme-bold)",
              },
              "br ~ br": {
                display: "none",
              },
              figcaption: {
                fontSize: theme("fontSize.sm")[0],
              },
              "a:has(img)": {
                textDecoration: "none",
              },
              a: {
                fontWeight: theme("fontWeight.normal"),
              },
            },
          },
          gray: {
            css: {
              // The theme function call needs to be stringified in order for it to work with <alpha-value>:
              // https://github.com/tailwindlabs/tailwindcss/issues/9143#issuecomment-1849773132
              "--tw-prose-body": "theme('colors.charcoal')",
              "--tw-prose-headings": "theme('colors.black')",
              "--tw-prose-lead": "theme('colors.black')",
              "--tw-prose-links": "theme('colors.primary.DEFAULT')",
              "--tw-prose-bold": "theme('colors.black')",
              "--tw-prose-counters": "theme('colors.charcoal')",
              "--tw-prose-bullets": "theme('colors.primary.DEFAULT')",
              "--tw-prose-hr": "theme('colors.black')",
              "--tw-prose-quotes": "theme('colors.dim')",
              "--tw-prose-quote-borders": "theme('colors.primary.DEFAULT')",
              "--tw-prose-captions": "theme('colors.nobel')",
              "--tw-prose-th-borders": "theme('colors.smoke')",
              "--tw-prose-td-borders": "theme('colors.smoke')",
              "--tw-prose-invert-body": "theme('colors.white')",
              "--tw-prose-invert-headings": "theme('colors.white')",
              "--tw-prose-invert-lead": "theme('colors.white')",
              "--tw-prose-invert-links": "theme('colors.white')",
              "--tw-prose-invert-bold": "theme('colors.white')",
              "--tw-prose-invert-counters": "theme('colors.white')",
              "--tw-prose-invert-bullets": "theme('colors.white')",
              "--tw-prose-invert-hr": "theme('colors.white')",
              "--tw-prose-invert-quotes": "theme('colors.white')",
              "--tw-prose-invert-quote-borders": "theme('colors.white')",
              "--tw-prose-invert-captions": "theme('colors.white')",
              "--tw-prose-invert-th-borders": "theme('colors.white')",
              "--tw-prose-invert-td-borders": "theme('colors.white')",
            },
          },
        };
      },
    },
  },
  plugins: [
    require("@tailwindcss/typography"),
    require("@tailwindcss/forms"),
    require("tailwindcss/plugin")(({ addVariant }) => {
      addVariant(
        "not-peer-placeholder-shown",
        ":merge(.peer):not(:placeholder-shown) ~ &",
      );
      addVariant("has-previous-sibling-not-hidden", "aside:not([hidden]) ~ &");
      addVariant("has-previous-sibling", "* ~ &");
    }),
  ],
  safelist: ["hyphens-auto"],
};
