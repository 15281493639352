"use client";

import { useSite } from "@/contexts/site/site.context";
import type { ReactNode } from "react";
type EmailLinkProps = {
  readonly className?: string;
  readonly emailAddress: string;
  readonly children?: ReactNode;
  readonly position?: string;
};
export function EmailLink({
  children,
  className,
  emailAddress,
  position
}: EmailLinkProps) {
  const {
    domain
  } = useSite();
  return <a className={className} data-k5a-pos={position} data-k5a-url={`https://${domain}/mailto`} // Kilkaya does not support mailto links, so we have to provide an url for it to work.
  href={`mailto:${emailAddress}`} data-sentry-component="EmailLink" data-sentry-source-file="EmailLink.component.tsx">
      {children ?? emailAddress}
    </a>;
}