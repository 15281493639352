"use client";

import { Translation } from "@/components/Translation/Translation.component";
export function ConsentLink() {
  return <button className="footer-link" onClick={() => {
    if (typeof Cookiebot !== "undefined") {
      Cookiebot.show();
    }
  }} type="button" data-sentry-component="ConsentLink" data-sentry-source-file="ConsentLink.component.tsx">
      <Translation model={{
      da: "Administrér samtykke",
      de: "Cookie-Einstellungen anpassen",
      en: "Manage consent",
      no: "Administrér samtykke",
      sv: "Hantera samtycke"
    }} data-sentry-element="Translation" data-sentry-source-file="ConsentLink.component.tsx" />
    </button>;
}