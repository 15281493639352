"use client";

import { useSite } from "@/contexts/site/site.context";
import type { DomId } from "@/hooks/ads/ads";
import { useAdSlot } from "@/hooks/ads/use-ad-slot.hook";
import clsx from "clsx";
type AdSlotProps = {
  readonly domId: DomId;
  readonly className?: string;
  readonly onBeforeShow?: (isEmpty: boolean) => void;
};
export function AdSlot({
  className,
  domId,
  onBeforeShow
}: AdSlotProps) {
  const {
    locale
  } = useSite();
  const {
    isAdEmpty
  } = useAdSlot({
    domId,
    onBeforeShow
  });
  return <aside aria-hidden={isAdEmpty} className={clsx("relative mx-auto flex flex-col items-center bg-whisper print:hidden", "opacity-100 before:absolute before:-top-2 before:left-0 before:select-none before:text-xs before:uppercase before:leading-tight before:text-silver before:content-[attr(data-label)]", isAdEmpty && "!hidden", className)} data-label={{
    da: "Annonce",
    de: "Anzeige",
    en: "Advertisement",
    no: "Annonse",
    sv: "Annons"
  }[locale]} hidden={isAdEmpty} id={domId} data-sentry-component="AdSlot" data-sentry-source-file="AdSlot.component.tsx" />;
}