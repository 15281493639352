"use client";

import debounce from "lodash.debounce";
import { useMemo } from "react";

export function withDebounce<
  DebouncedFunction extends (...parameters: ReadonlyArray<unknown>) => void,
>(debouncedFunction: DebouncedFunction, timeoutMilliseconds: number) {
  let timerHandle: ReturnType<typeof setTimeout> | undefined;

  return (...parameters: Parameters<typeof debouncedFunction>) => {
    clearTimeout(timerHandle);
    timerHandle = setTimeout(
      () => debouncedFunction(parameters),
      timeoutMilliseconds,
    );
  };
}
// More about the two ways of debouncing: https://stackoverflow.com/a/61140811
// https://www.developerway.com/posts/debouncing-in-react
export function useDebouncedCallback<T>(
  callback: (args: T) => unknown,
  delay: number,
) {
  const debouncedCallback = useMemo(
    () => debounce(callback, delay),
    [callback, delay],
  );

  return debouncedCallback;
}

// To make clear what this actually does, compared to it's fellows above, it has been aliased
export { useDebounce as useDebouncedValue } from "usehooks-ts";
