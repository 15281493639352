import type { BreakpointValues } from "@/hooks/use-viewport-breakpoint.hook";

const sortedBreakpoints = ["xs", "sm", "md", "lg", "xl", "2xl"];

function lowerThan(from: BreakpointValues, to: BreakpointValues) {
  return sortedBreakpoints.indexOf(from) < sortedBreakpoints.indexOf(to);
}

function higherThan(from: BreakpointValues, to: BreakpointValues) {
  return sortedBreakpoints.indexOf(from) > sortedBreakpoints.indexOf(to);
}

function equalTo(from: BreakpointValues, to: BreakpointValues) {
  return sortedBreakpoints.indexOf(from) === sortedBreakpoints.indexOf(to);
}

export function compareBreakpoints(
  compareFrom: BreakpointValues | null,
  operator: "<" | "<=" | "=" | ">" | ">=",
  compareTo: BreakpointValues | null,
) {
  if (compareFrom === null || compareTo === null) return false;

  switch (operator) {
    case "<":
      return lowerThan(compareFrom, compareTo);

    case "<=":
      return (
        lowerThan(compareFrom, compareTo) || equalTo(compareFrom, compareTo)
      );

    case ">":
      return higherThan(compareFrom, compareTo);

    case ">=":
      return (
        higherThan(compareFrom, compareTo) || equalTo(compareFrom, compareTo)
      );

    default:
      return equalTo(compareFrom, compareTo);
  }
}
